const agent = window.superagent;
let submitting = false;

document.querySelector('.J-submit').addEventListener('click', e => {
    if (submitting) return;
    submitting = true;
    const nameInput = document.querySelector('.J-name');
    const phoneInput = document.querySelector('.J-phone');
    const name = nameInput.value;
    const phone = phoneInput.value;
    const referrer = document.referrer;
    if (!name) {
        return alert('请填写姓名');
    }
    if (!/^1[3456789]\d{9}$/.test(phone)) {
        return alert('手机号码有误，请重填');
    }
    agent
        .post('https://www.vsattech.com/api/clue')
        .send({
            name,
            phone,
            referrer,
        })
        .then(data => {
            alert('提交成功!');
            submitting = false;
            nameInput.value = '';
            phoneInput.value = '';
        })
        .catch(() => {
            alert('提交失败!');
            submitting = false;
        });
});
